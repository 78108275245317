/* eslint-disable */
import React, { useEffect, useState, useRef, useCallback } from "react";
import Axios from "axios";
import ApiConfig from "_config/ApiConfig";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Icon from "@mui/material/Icon";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DefaultNavbar from "examples/Navbars/DefaultNavbar"
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import routes from "routes";
import bgImage from "assets/images/select.jpg";
//import TextareaAutosize from 'react-textarea-autosize';
//import TextareaAutosize from '@mui/base/TextareaAutosize';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//import GoogleMapReact from 'google-map-react';
import Location_marker_pin_map_gps from "assets/images/Location_marker_pin_map_gps.png";

import { APIProvider, Map, AdvancedMarker, Marker } from '@vis.gl/react-google-maps';

export default function AdminItem() {

  const [dropdown, setDropdown] = useState(null);//Show Item
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const [dropdown2, setDropdown2] = useState(null);// Select Categorie
  const openDropdown2 = ({ currentTarget }) => setDropdown2(currentTarget);
  const closeDropdown2 = () => setDropdown2(null);

  const [dropdown3, setDropdown3] = useState(null);//  Select SubCategorie
  const openDropdown3 = ({ currentTarget }) => setDropdown3(currentTarget);
  const closeDropdown3 = () => setDropdown3(null);

  const [dropdown4, setDropdown4] = useState(null);//  Select Price
  const openDropdown4 = ({ currentTarget }) => setDropdown4(currentTarget);
  const closeDropdown4 = () => setDropdown4(null);

  const [dropdown5, setDropdown5] = useState(null);//Select Culinarie
  const openDropdown5 = ({ currentTarget }) => setDropdown5(currentTarget);
  const closeDropdown5 = () => setDropdown5(null);

  const [dropdown6, setDropdown6] = useState(null);//Select Style
  const openDropdown6 = ({ currentTarget }) => setDropdown6(currentTarget);
  const closeDropdown6 = () => setDropdown6(null);

  const [dropdown7, setDropdown7] = useState(null);//Select Level
  const openDropdown7 = ({ currentTarget }) => setDropdown7(currentTarget);
  const closeDropdown7 = () => setDropdown7(null);

  const [dropdown8, setDropdown8] = useState(null);//Select Explore
  const openDropdown8 = ({ currentTarget }) => setDropdown8(currentTarget);
  const closeDropdown8 = () => setDropdown8(null);

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };
  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const [isListItem, setListItem] = useState([]);//rempli la liste 
  const [isCountItem, setCountItem] = useState(0);// compte les elements de la liste
  const [isListCategorie, setListCategorie] = useState([]);//rempli la liste 
  const [isListSubCategorie, setListSubCategorie] = useState([]);//rempli la liste 
  const [isListCatSubCatItem, setListCatSubCatItem] = useState([]);//rempli la liste 

  const [isListPrice, setListPrice] = useState([]);//rempli la liste 
  const [isListCulinarie, setListCulinarie] = useState([]);//rempli la liste 
  const [isListStyle, setListStyle] = useState([]);//rempli la liste 
  const [isListLevel, setListLevel] = useState([]);//rempli la liste 
  const [isListExplorer, setListExplorer] = useState([]);//rempli la liste 

  const [isListPriceCulinarieItem, setListPriceCulinarieItem] = useState([]);//rempli la liste 
  const [isListStyleLevelExplorerItem, setListStyleLevelExplorerItem] = useState([]);//rempli la liste 
  const [isCreateItem, createItem] = useState(0);
  const [isEditItem, editItem] = useState(0);
  const [isSelectItem, selectItem] = useState(0);

  const [isDescriptionItem, descriptionItem] = useState([]);
  const [isCatItem, catItem] = useState([]);
  const [isSubItem, subItem] = useState([]);

  const [isStyleItem, styleItem] = useState([]);
  const [isPriceItem, priceItem] = useState([]);
  const [isCulinarieItem, culinarieItem] = useState([]);
  const [isLevelItem, levelItem] = useState([]);
  const [isExplorerItem, explorerItem] = useState([]);



  const [isMapSelectItem, mapSelectItem] = useState(0);

  const [isSetShowInformation, setShowInformation] = useState(0);
  const [isSetItem, setItem] = useState([]);
  //const [isSetHideShow, setHideShow] = useState(0);A FAIRE

  const [messageCreate, resultCreate] = useState([]);

  const [messageMaj, resultMaj] = useState([]);
  const [message, searchResult] = useState([]);
  const [message2, searchResult2] = useState([]);
  const [message3, searchResult3] = useState([]);
  const [messageDescription, setMessageDescription] = useState([]);
  const [markerupdateMessage, setMarkerupdateMessage] = useState([]);



  const addDescription = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      if (!addDescriptionInfo.descriptionArea) {
        setMessageDescription('Description is missing');
        return false;
      }

      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectAddDescription.php`, {
        productDescription: addDescriptionInfo.descriptionArea,
        id_std: sessionStorage.getItem("id_std"),

      })
        .then(res => {
          //console.log(res.data.query)
          if (res.data.productdescriptiondata['success'] === true) {
            setMessageDescription('Description saved');
            //getItemData();
          }
          else {
            setMessageDescription('LDescription is missinge');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 7.84305,
      lng: 98.33938
    },
    zoom: 11
  };

  const [latitudes, setLatitude] = useState(0);
  const [longitudes, setLongitude] = useState(0);
  const [names, setNames] = useState('');
  console.log(latitudes);
  console.log(longitudes);
  console.log(names);

  //const positionItem = { lat: latitudes, lng: longitudes };

  const [addDescriptionInfo, setdescriptionInfo] = useState({
    designationLabel: '',
    // descriptionArea: '',
  });

  const onChangeValueArea = (e) => {
    descriptionItem(e.target.value);
    setdescriptionInfo({
      ...addDescriptionInfo,
      [e.target.name]: e.target.value
    });
  }
  const [isLibelle, setLibelle] = useState();

  const [addItemInfo, setItemInfo] = useState({
    itemLabel: '',
  })
  const onChangeValueMix = (e) => {
    setLibelle(e.target.value);
    setItemInfo({
      ...addItemInfo,
      [e.target.name]: e.target.value
    })
  }

  //PARTIEL
  const onChangeValue = (e) => {
    setNameItem({
      ...isNameItem,
      [e.target.name]: e.target.value
    });
    /* setNameImg({
      ...isNameImg,
      [e.target.name]: e.target.value
    }); */
  }

  const [isNameItem, setNameItem] = useState({
    nameItem: '',
  });
  /* const [isNameImg, setNameImg] = useState({
    nameImg: '',
  }); */

  //FONCTION
  const getAllItem = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllItem.php`)
        .then(res => {
          if (res.data.success === true) {
            setListItem(res.data.itemdata);
            setCountItem(res.data.recordCount);
            //console.log(res.data.itemdata)
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };



  const createTitleItem = async (event) => {
    if (isNameItem.nameItem.length <= 2) {
      resultCreate('3 characters minimum');
      return false;
    }
    else {
      resultCreate();
    }
    /*  if (isNameImg.nameImg.length <= 2) {
       resultCreate('3 characters minimum');
       return false;
     }
     else {
       resultCreate();
     } */
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectInsertItem.php`, {
        id_pro: 1,
        chaine: isNameItem.nameItem,
        //imag: isNameImg.nameImg,
      })
        .then(res => {
          if (res.data.success === true) {
            getAllItem();
            resultCreate('Registred Information');
          }
          else {
            resultCreate('error on registred');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getItem = async (id) => {
    sessionStorage.setItem("id_std", id);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetItem.php`, {
        id_std: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(1);
            searchResult(res.data.design_std + " selected");
            setItem(res.data.getitemdata);
            setLibelle(res.data.design_std);
            resultMaj();
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const updateItem = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUpdateItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        libelleItem: addItemInfo.itemLabel,

      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('Information updated');
            getAllItem();
          }
          else {
            resultMaj('error on updated');
          }
        })
    } catch (error) { throw error; }
  };

  const delItem = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectDeleteItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('Information deleted');
            sessionStorage.removeItem("id_std");
            getAllItem();
          }
          else {
            resultMaj('error on deleted');
          }
        })
    } catch (error) { throw error; }
  };


  const hideItem = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectHideShowItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        id_pro: 2,
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('hidden Information');
            getAllItem();
          }
          else {
            resultMaj('error on hide');
          }
        })
    } catch (error) { throw error; }
  };

  const getItemSelect = async (id_std) => {
    sessionStorage.setItem("id_std", id_std);
    sessionStorage.setItem("table", "cat_tblstandard");
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetItem.php`, {
        id_std: id_std,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult(res.data.design_std + " selected")
            getPicturesData();
            getAllCategorie();
            getAllSubCategorie();
            getAllPrice();
            getAllCulinarie();
            getAllStyle();
            getAllLevel();
            getAllExplorer();
            loadItemDescription();
            getLoadMarkers();
            sessionStorage.setItem("design_std", res.data.design_std);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getAllCategorie = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllCategorie.php`)
        .then(res => {
          if (res.data.success === true) {
            setListCategorie(res.data.categoriedata);
            //console.log(res.data.categoriedata)
            //setCountItem(res.data.recordCount);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getCategorieSelect = async (id_cat) => {
    sessionStorage.setItem("id_cat", id_cat);
    //sessionStorage.setItem("table", "cat_tblstandard");
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetCategorie.php`, {
        id_cat: id_cat,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.design_cat + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getAllSubCategorie = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllSubCategorie.php`)
        .then(res => {
          if (res.data.success === true) {
            setListSubCategorie(res.data.subcategoriedata);
            //console.log(res.data.subcategoriedata)
            //setCountItem(res.data.recordCount);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getSubCategorieSelect = async (id_sub) => {
    sessionStorage.setItem("id_sub", id_sub);
    //sessionStorage.setItem("table", "cat_tblstandard");
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetSubCategorie.php`, {
        id_sub: id_sub,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult3(res.data.design_sub + " selected")
            //getPicturesData();
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const insertItemCatSubCat = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectInsertCatSubCatItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        id_cat: sessionStorage.getItem("id_cat"),
        id_sub: sessionStorage.getItem("id_sub"),
      })
        .then(res => {
          if (res.data.success === true) {
            setListCatSubCatItem(res.data.getcatsubcatitemdata);
          }
          else {
            console.log('error');
          }
        })
    } catch (error) { throw error; }
  };
  const updateItemPriceCulinarie = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUpdatePriceCulinarieItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        id_price: sessionStorage.getItem("id_price"),
        id_cul: sessionStorage.getItem("id_cul"),
      })
        .then(res => {
          if (res.data.success === true) {
            setListPriceCulinarieItem(res.data.getpriceculinarieitemdata);
          }
          else {
            console.log('error');
          }
        })
    } catch (error) { throw error; }
  };
  const updateItemStyleLevelExplorer = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUpdateStyleLevelExplorerItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        id_sty: sessionStorage.getItem("id_sty"),
        id_lev: sessionStorage.getItem("id_lev"),
        id_exp: sessionStorage.getItem("id_exp"),
      })
        .then(res => {
          if (res.data.success === true) {
            setListStyleLevelExplorerItem(res.data.getstylelevelexploreritemdata);
          }
          else {
            console.log('error');
          }
        })
    } catch (error) { throw error; }
  };

  const delCatSubCatItem = async (id_css) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectDeleteSubCatItem.php`, {
        id_css: id_css,
      })
        .then(res => {
          if (res.data.success === true) {
            //resultMaj('Information deleted');
            setListCatSubCatItem('');
          }
          else {
            console.log('error');
            //resultMaj('error on deleted');
          }
        })
    } catch (error) { throw error; }
  };

  const showItem = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectHideShowItem.php`, {
        id_std: sessionStorage.getItem("id_std"),
        id_pro: 1,
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('show Information');
            getAllItem();
          }
          else {
            resultMaj('error on show');
          }
        })
    } catch (error) { throw error; }
  };
  ///////////////////////

  const getAllPrice = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllPrice.php`)
        .then(res => {
          if (res.data.success === true) {
            setListPrice(res.data.pricedata);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getAllCulinarie = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllCulinarie.php`)
        .then(res => {
          if (res.data.success === true) {
            setListCulinarie(res.data.culinariedata);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getAllStyle = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllStyle.php`)
        .then(res => {
          if (res.data.success === true) {
            setListStyle(res.data.styledata);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getAllLevel = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllLevel.php`)
        .then(res => {
          if (res.data.success === true) {
            setListLevel(res.data.leveldata);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getAllExplorer = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllExplorer.php`)
        .then(res => {
          if (res.data.success === true) {
            setListExplorer(res.data.explorerdata);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getPriceSelect = async (id_price) => {
    sessionStorage.setItem("id_price", id_price);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetPrice.php`, {
        id_price: id_price,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.price_devise + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getCulinarieSelect = async (id_cul) => {
    sessionStorage.setItem("id_cul", id_cul);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetCulinarie.php`, {
        id_cul: id_cul,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.design_cul + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getStyleSelect = async (id_sty) => {
    sessionStorage.setItem("id_sty", id_sty);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetStyle.php`, {
        id_sty: id_sty,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.design_sty + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const loadItemDescription = async () => {//ici <) pb quand 1 item appartient à+sieurs categorie
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemDescription.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            descriptionItem(res.data.itemdescriptiondata);
          }
          else {
            descriptionItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const loadItemCatSubCat = async () => {//ici <) pb quand 1 item appartient à+sieurs categorie
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemCatSubCat.php`, {
        id_std: sessionStorage.getItem("id_std"),
        //id_cat: sessionStorage.getItem("id_cat"),
        //id_sub: sessionStorage.getItem("id_sub"),

      })
        .then(res => {
          if (res.data.success === true) {
            catItem(res.data.itemcatdata);
            subItem(res.data.itemsubdata);
          }
          else {
            catItem('not found');
            subItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const loadItemStyle = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemStyle.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            styleItem(res.data.itemstyledata);
          }
          else {
            styleItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const loadItemPrice = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemPrice.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            priceItem(res.data.itempricedata);
          }
          else {
            priceItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const loadItemCulinarie = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemCulinarie.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            culinarieItem(res.data.itemculinariedata);
          }
          else {
            culinarieItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const loadItemLevel = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemLevel.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            levelItem(res.data.itemleveldata);
          }
          else {
            levelItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const loadItemExplorer = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectLoadItemExplorer.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            explorerItem(res.data.itemexplorerdata);
          }
          else {
            explorerItem('not found');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  const getLevelSelect = async (id_lev) => {
    sessionStorage.setItem("id_lev", id_lev);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetLevel.php`, {
        id_lev: id_lev,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.design_lev + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getExplorerSelect = async (id_exp) => {
    sessionStorage.setItem("id_exp", id_exp);
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetExplorer.php`, {
        id_exp: id_exp,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult2(res.data.design_exp + " selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };
  ///

  const position = useRef(null);
  const [images, showPicture] = useState([]);
  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetPictureStd.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res.data.picturedata)
          showPicture(res.data.picturedata);//alimente la gallery
          return;
        })
    } catch (error) { throw error; }
  };


  const [isLatItem, setLatItem] = useState([]);
  const [isLngItem, setLngItem] = useState([]);

  const handleMapClick = useCallback((e) => {
    setLatItem(e.detail.latLng.lat);
    setLngItem(e.detail.latLng.lng);
    setMarkerPositions((positions) => [...positions, e.detail.latLng]);
    mapSelectItem(1);
    /* console.log(isLatItem);
    console.log(isLngItem); */
  }, []);
  const [markerPositions, setMarkerPositions] = useState([]);

  const insertMarkerItem = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectInsertMarkerStd.php`, {
        id_std: sessionStorage.getItem("id_std"),
        latItem: isLatItem,
        lngItem: isLngItem,
        chaine: sessionStorage.getItem("design_std"),
      })
        .then(res => {
          if (res.data.success === true) {
            setMarkerupdateMessage('Marker registred for' + sessionStorage.getItem("design_std"));
            getLoadMarkers();
          }
          else {
            console.log('error');
          }
        })
    } catch (error) { throw error; }
  };


  const getLoadMarkers = async () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectStandardMarkerMap.php`, {
        id_std: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //loadMarkers(res.data.markerdata);
          setLatitude(res.data.latitudedata);
          setLongitude(res.data.longitudedata);
          setNames(res.data.namedata);
          return;
        })
    }
    catch (error) {
      // setError(error);
      console.log(error)
    }
    /* finally {
      setIsLoading(false);
    } */
  };
  useEffect(() => {
    getAllItem();//chargemement de la liste par defaut   
    resetIndexData();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.01),
              rgba(gradients.dark.state, 0.01)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, lg: 12, md: 12 }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h4"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            ><br></br>
              <br></br><br></br>
              <br></br>
              Item Admin
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {isCountItem} founds
              <br></br>
            </MKTypography>
            <MKTypography variant="body1" color="warning" opacity={0.8} mt={1} mb={3}>
              <h4>{message}</h4>
              <h4>{message2}</h4>
              <h4>{message3}</h4>
            </MKTypography>
            {isListPriceCulinarieItem.length !== 0 ?
              (<>
                {isListCatSubCatItem.map((itemCss) => (
                  <>
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3} key={itemCss.id_css}>
                      Item : {itemCss.design_std}&nbsp;
                      Categorie : {itemCss.design_cat}&nbsp;
                      SubCategorie : {itemCss.design_sub}&nbsp;
                      ID : {itemCss.id_css}&nbsp;
                      <Icon fontSize="small" onClick={() => (delCatSubCatItem(itemCss.id_css))}>delete</Icon>
                    </MKTypography>
                  </>
                ))}


              </>) : (<></>)}
            {isListPriceCulinarieItem.length !== 0 ?
              (<>
                {isListPriceCulinarieItem.map((itemPriceCul) => (
                  <>
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3} key={itemPriceCul.id_std}>
                      Item : {itemPriceCul.design_std}&nbsp;
                      Price : {itemPriceCul.price}&nbsp;
                      Devise : {itemPriceCul.devise_price}&nbsp;
                      Culinarie : {itemPriceCul.design_cul}&nbsp;
                      ID : {itemPriceCul.id_std}&nbsp;
                    </MKTypography>
                  </>
                ))}

              </>) : (<></>)}
            {isListStyleLevelExplorerItem.length !== 0 ?
              (<>
                {isListStyleLevelExplorerItem.map((itemStyleLevelExplorer) => (
                  <>
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3} key={itemStyleLevelExplorer.id_std}>
                      Item : {itemStyleLevelExplorer.design_std}&nbsp;
                      Style : {itemStyleLevelExplorer.design_sty}&nbsp;
                      Level : {itemStyleLevelExplorer.design_lev}&nbsp;
                      Explorer : {itemStyleLevelExplorer.design_exp}&nbsp;
                      ID : {itemStyleLevelExplorer.id_std}&nbsp;
                      {/* <Icon fontSize="small" onClick={() => (delCatSubCatItem(itemCss.id_css))}>delete</Icon> */}
                    </MKTypography>
                  </>
                ))}

              </>) : (<></>)}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Stack direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 6 }}>
            <MKButton variant="gradient" color="info" onClick={openDropdown}>
              Show Item <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKButton>
            {/*               LA LISTE */}
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
              {isListItem.map((item) => (
                <MenuItem key={item.id_std}
                  value={item.id_std}
                  onClick={() => (getItemSelect(item.id_std), selectItem(1), createItem(0), editItem(0))}>
                  {item.design_std}
                </MenuItem>
              ))}
            </Menu>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={() => (createItem(1), editItem(0), selectItem(0))}>
              create Item
            </MKButton>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={() => (editItem(1), createItem(0), selectItem(0))}>
              edit/delete/hide Item
            </MKButton>

          </Stack>
        </Grid>
        {isCreateItem === 1 ? (
          <>

            <MKBox px={1} width="100%" height="60vh" mx="auto" position="relative" zIndex={2}>
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                  <Card>
                    <MKBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      mx={6}
                      mt={-3}
                      p={2}
                      mb={1}
                      textAlign="center"
                    >
                      <MKTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Item
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={4} pb={3} px={3}>
                      <MKBox component="form" role="form" onSubmit={createTitleItem}>
                        <MKBox mb={4}>
                          <MKInput
                            id="nameItem"
                            name="nameItem"
                            variant="outlined"
                            onChange={onChangeValue}
                            label="Item Name"
                            placeholder="Write name"
                            autoComplete="off"
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </MKBox>
                        {/* <MKBox mb={2}>
                          <MKInput
                            id="nameImg"
                            name="nameImg"
                            variant="outlined"
                            onChange={onChangeValue}
                            label="Image Name"
                            placeholder="Write name"
                            autoComplete="off"
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </MKBox> */}
                        <MKBox mt={4} mb={1} px={5}>
                          <MKButton variant="gradient" color="info" fullWidth onClick={createTitleItem}>
                            Save
                          </MKButton>
                          <MKTypography variant="button" color="text" fontWeight="medium">
                            {messageCreate}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </Card>
                </Grid>
              </Grid>
            </MKBox>
          </>
        ) :
          <></>}
        {isEditItem === 1 ? (<>
          <MKBox px={1} width="100%" height="60vh" mx="auto" position="relative" zIndex={2}>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={10} lg={8} xl={8}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={6}
                    mt={-3}
                    p={1}
                    mb={1}
                    textAlign="center"
                  >
                    <MKTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                      Items list
                    </MKTypography>
                  </MKBox>
                  <Grid container>
                    <Grid size={{ xs: 10, lg: 5, md: 5 }} mx="auto">
                      {/*  <MKTypography variant="h5" color="white" my={1}>
                 Titre
                </MKTypography> */}
                      <br></br>
                      {isListItem.map((item) => (
                        <>
                          <MKTypography
                            component="a"
                            href="#"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                              width: "max-content",
                              display: "flex",
                              alignItems: "center",
                              "& .material-icons-round": {
                                fontSize: "1.125rem",
                                transform: "translateX(3px)",
                                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                              },
                              "&:hover .material-icons-round, &:focus .material-icons-round": {
                                transform: "translateX(6px)",
                              },
                            }}
                            key={item.id_std}
                            onClick={() => getItem(item.id_std)}
                          >
                            {item.id_pro == 1 ? <>
                              <VisibilityIcon />
                            </> : <><VisibilityOffIcon /> </>}&nbsp; {item.design_std}
                            <Icon sx={{ fontWeight: "bold" }} onClick={() => getItem(item.id_std)}>arrow_forward</Icon>
                          </MKTypography>
                        </>
                      ))}
                    </Grid>
                    <Grid size={{ xs: 10, lg: 5, md: 5 }} mx="auto">
                      {isSetShowInformation === 1 ? (<>
                        {isSetItem.map((itemStd) => (
                          <>
                            <form onSubmit={updateItem}>
                              <MKTypography variant="h5" my={1} color="text">
                                {itemStd.design_std}
                              </MKTypography>
                              <MKInput
                                id="itemLabel"
                                name="itemLabel"
                                variant="standard"
                                onChange={onChangeValueMix}
                                value={isLibelle}
                                autoComplete="off"
                                required
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={updateItem}>save</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                  Save changes
                                </MKTypography>
                              </MKBox>
                            </form>
                            <form onSubmit={delItem}>
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={delItem}>delete</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                  Delete information
                                </MKTypography>
                              </MKBox>
                            </form>
                            <form onSubmit={hideItem}>
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={hideItem}><VisibilityOffIcon /></Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                </MKTypography>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={showItem}><VisibilityIcon /></Icon>
                                </MKBox>
                              </MKBox>
                              <h6>{messageMaj}</h6>
                            </form>
                          </>
                        )
                        )}
                      </>) : (<></>)
                      }
                    </Grid>
                  </Grid>
                  <br></br>
                </Card>
              </Grid>
            </Grid>
          </MKBox>

          <br></br>
        </>) : <></>}
        <br></br><br></br>
        {isSelectItem === 1 ? (<>
          <Container justifyContent="center">
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <Card>
                  <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                    ref={position}
                    items={images}
                    showThumbnails={true}
                    renderLeftNav={(onClick, disabled) => {
                      return (
                        <button style={{ display: 'none' }}
                          className='image-gallery-left-nav'
                          disabled={disabled}
                          onClick={onClick}
                        />
                      );
                    }}
                    renderRightNav={(onClick, disabled) => {
                      return (
                        <button style={{ display: 'none' }}
                          className='image-gallery-right-nav'
                          disabled={disabled}
                          onClick={onClick}
                        />
                      );
                    }}
                  /* renderCustomControls={(onClick) => {
                    if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                      return (
                        <Button
                          color="transparent"
                          size="sm"
                          round
                          onClick={() => getItemRotate(sessionStorage.getItem("id_std"), sessionStorage.getItem("ctr_index"))}
                        ><RotateRightIcon /></Button>
                      );
                    }
                  }} */
                  />

                </Card>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="50%">
              <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={6}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <Card>
                    <ImageUpload id='1'
                      addButtonProps={{ round: true }}
                      changeButtonProps={{ round: true }}
                      removeButtonProps={{ round: true, color: "danger" }}
                      uploadButtonProps={{ round: true, color: "info" }}
                    />
                  </Card>
                </MKBox>
              </Grid>
              <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={6}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <Card>
                    <ImageUpload id='2'
                      addButtonProps={{ round: true }}
                      changeButtonProps={{ round: true }}
                      removeButtonProps={{ round: true, color: "danger" }}
                      uploadButtonProps={{ round: true, color: "info" }}
                    />
                  </Card>
                </MKBox>
              </Grid>
              <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={6}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <Card>
                    <ImageUpload id='3'
                      addButtonProps={{ round: true }}
                      changeButtonProps={{ round: true }}
                      removeButtonProps={{ round: true, color: "danger" }}
                      uploadButtonProps={{ round: true, color: "info" }}
                    /></Card>
                </MKBox>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Grid container justifyContent="center">
              <MKButton variant="gradient" color="info" onClick={getPicturesData}>
                Reload <Icon sx={dropdownIconStyles}> <RefreshIcon /></Icon>
              </MKButton>
            </Grid>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <form onSubmit={addDescription}>
                  <Grid container justify="center">
                    <Grid item xs={12} pr={1} mb={6}>
                      <MKBox
                        variant="gradient"
                        bgColor="white"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={6}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                      >
                        <MKTypography
                          variant="h6"
                          color="info"
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["3xl"],
                            },
                          })}
                        >
                          Item description
                          <br></br>
                          <br></br>
                        </MKTypography>

                        <MKInput
                          id="descriptionArea"
                          name="descriptionArea"
                          color="primary"
                          variant="standard"
                          onChange={onChangeValueArea}
                          value={isDescriptionItem}
                          autoComplete="off"
                          required
                          //label="Description"
                          //placeholder="write a description"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                        />
                        {/*   <MKInput
                                id="itemLabel" 
                                name="itemLabel"
                                variant="standard"
                                onChange={onChangeValueMix}
                                value={isLibelle}
                                autoComplete="off"
                                required
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              /> */}
                      </MKBox>
                    </Grid>

                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    justifyContent="flex-end"
                    textAlign="right"
                    ml="auto"
                  >
                    <MKButton variant="gradient" color="info" onClick={addDescription}>
                      save&nbsp;<Icon fontSize="small">save</Icon>
                    </MKButton>

                  </Grid>
                  <br />
                  <Grid container justify="center">
                    <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>

                      <MKTypography variant="body1" color="warning" opacity={0.8} mt={1} mb={3}>
                        <h4>{messageDescription}</h4>
                      </MKTypography>

                      <br></br>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={loadItemCatSubCat}
                  >
                    <Typography component="span">Categorie</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isCatItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    onClick={loadItemCatSubCat}
                  >
                    <Typography component="span">SubCategorie</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isSubItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <Grid container justifyContent="center">
              <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 6 }}>
                <MKButton variant="gradient" color="info" onClick={openDropdown2}>
                  Select Categorie <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               LA LISTE */}
                <Menu anchorEl={dropdown2} open={Boolean(dropdown2)} onClose={closeDropdown2}>
                  {isListCategorie.map((itemCat) => (
                    <MenuItem key={itemCat.id_cat}
                      value={itemCat.id_cat}
                      onClick={() => (getCategorieSelect(itemCat.id_cat))}
                    >
                      {itemCat.design_cat}
                    </MenuItem>
                  ))}
                </Menu>
                <MKButton variant="gradient" color="info" onClick={openDropdown3}>
                  Select SubCategorie <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               LA LISTE */}
                <Menu anchorEl={dropdown3} open={Boolean(dropdown3)} onClose={closeDropdown3}>
                  {isListSubCategorie.map((itemSubCat) => (
                    <MenuItem key={itemSubCat.id_sub}
                      value={itemSubCat.id_sub}
                      onClick={() => (getSubCategorieSelect(itemSubCat.id_sub))}>
                      {itemSubCat.design_sub}
                    </MenuItem>
                  ))}
                </Menu>
                <MKBox display="flex" alignItems="left" p={1}>
                  <MKBox
                    width="6rem"
                    height="3rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <MKButton variant="gradient" color="info" onClick={insertItemCatSubCat}>
                      save&nbsp;<Icon fontSize="small">save</Icon>
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Stack>
            </Grid>

            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={loadItemPrice}
                  >
                    <Typography component="span">Price</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isPriceItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    onClick={loadItemCulinarie}

                  >
                    <Typography component="span">Culinarie</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isCulinarieItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <Grid container justifyContent="center">
              <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 6 }}>
                <MKButton variant="gradient" color="info" onClick={openDropdown4}>
                  Select Price <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               fil_price*/}
                <Menu anchorEl={dropdown4} open={Boolean(dropdown4)} onClose={closeDropdown4}>
                  {isListPrice.map((itemPrice) => (
                    <MenuItem key={itemPrice.id_price}
                      value={itemPrice.id_price}
                      onClick={() => (getPriceSelect(itemPrice.id_price))}
                    >
                      {itemPrice.price}  {itemPrice.devise_price}
                    </MenuItem>
                  ))}
                </Menu>
                <MKButton variant="gradient" color="info" onClick={openDropdown5}>
                  Select Culinarie <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               fil culinarie */}
                <Menu anchorEl={dropdown5} open={Boolean(dropdown5)} onClose={closeDropdown5}>
                  {isListCulinarie.map((itemCul) => (
                    <MenuItem key={itemCul.id_cul}
                      value={itemCul.id_cul}
                      onClick={() => (getCulinarieSelect(itemCul.id_cul))}
                    >
                      {itemCul.design_cul}
                    </MenuItem>
                  ))}
                </Menu>
                <MKBox display="flex" alignItems="left" p={1}>
                  <MKBox
                    width="6rem"
                    height="3rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <MKButton variant="gradient" color="info" onClick={updateItemPriceCulinarie}>
                      save&nbsp;<Icon fontSize="small">save</Icon>
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Stack>
            </Grid>

            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={loadItemStyle}
                  >
                    <Typography component="span">Style</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isStyleItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    onClick={loadItemLevel}
                  >
                    <Typography component="span">Level</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isLevelItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    onClick={loadItemExplorer}
                  >
                    <Typography component="span">Explorer</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {isExplorerItem}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </Grid>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <Grid container justifyContent="center">
              <Stack direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 6 }}>
                <MKButton variant="gradient" color="info" onClick={openDropdown6}>
                  Select Style <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               fil_style*/}
                <Menu anchorEl={dropdown6} open={Boolean(dropdown6)} onClose={closeDropdown6}>
                  {isListStyle.map((itemSty) => (
                    <MenuItem key={itemSty.id_sty}
                      value={itemSty.id_sty}
                      onClick={() => (getStyleSelect(itemSty.id_sty))}
                    >
                      {itemSty.design_sty}
                    </MenuItem>
                  ))}
                </Menu>
                <MKButton variant="gradient" color="info" onClick={openDropdown7}>
                  Select Level <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               fil level */}
                <Menu anchorEl={dropdown7} open={Boolean(dropdown7)} onClose={closeDropdown7}>
                  {isListLevel.map((itemLev) => (
                    <MenuItem key={itemLev.id_lev}
                      value={itemLev.id_lev}
                      onClick={() => (getLevelSelect(itemLev.id_lev))}
                    >
                      {itemLev.design_lev}
                    </MenuItem>
                  ))}
                </Menu>
                <MKButton variant="gradient" color="info" onClick={openDropdown8}>
                  Select Explorer <Icon sx={dropdownIconStyles}>expand_more</Icon>
                </MKButton>
                {/*               fil level */}
                <Menu anchorEl={dropdown8} open={Boolean(dropdown8)} onClose={closeDropdown8}>
                  {isListExplorer.map((itemExp) => (
                    <MenuItem key={itemExp.id_exp}
                      value={itemExp.id_exp}
                      onClick={() => (getExplorerSelect(itemExp.id_exp))}
                    >
                      {itemExp.design_exp}
                    </MenuItem>
                  ))}
                </Menu>
                <MKBox display="flex" alignItems="left" p={1}>
                  <MKBox
                    width="6rem"
                    height="3rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    coloredShadow="info"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="xl"
                  >
                    <MKButton variant="gradient" color="info" onClick={updateItemStyleLevelExplorer}
                    >
                      save&nbsp;<Icon fontSize="small">save</Icon>
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Stack>
            </Grid>

            <br></br>
            <hr></hr>
            <br></br>
            <br></br>

          </Container>
          {/* BOX example
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">            
            <Grid item xs={11} sm={9} md={4} lg={4} xl={4}>
              <MKBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                coloredShadow="info"
                mx={6}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
              </MKBox>
            </Grid>
          </Grid> */}

       

        <br></br>
        <br></br>

        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          {isMapSelectItem === 1 ? (<>
            <Container>
              <Grid container justifyContent="center">
                <Stack direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 6 }}>
                  <MKBox display="flex" alignItems="left" p={1}>
                    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                      To register this marker for {sessionStorage.getItem("design_std")} Click on Save Button&nbsp;
                      <br></br>
                      {markerupdateMessage}
                    </MKTypography>
                    <MKBox
                      width="6rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKButton variant="gradient" color="info" onClick={insertMarkerItem}>
                        save&nbsp;<Icon fontSize="small">save</Icon>
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </Stack>
              </Grid>
            </Container>


          </>) : <></>}
          <Grid container justifyContent="center">


            <APIProvider apiKey={'AIzaSyDu94qM3uJh3t8IjHx6Uvksqb-D2sbJPaQ'} /* onLoad={() => console.log('Maps API has loaded.')} */>
              <Map
                mapId={"bf51a910020fa25a"}
                style={{ width: '50%', height: '100vh' }}
                defaultCenter={{ lat: 7.84305, lng: 98.33938 }}
                defaultZoom={11}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                onClick={(e) => handleMapClick(e)}
              >
                {markerPositions.map((position, i) => (
                  <AdvancedMarker key={i} position={position} />
                ))}
                {/* <Marker
                  position={positionItem}
                  icon={Location_marker_pin_map_gps}
                  title={names}
                /> */}
                <Marker
                  position={{
                    lat: parseFloat(latitudes), // Coordonnées pour le marqueur
                    lng: parseFloat(longitudes),
                  }}
                  icon={Location_marker_pin_map_gps}
                  //title={names} // Titre affiché sur le marqueur
                  label={{
                    text: names,
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                />
              </Map>
            </APIProvider>

          </Grid>
        </Grid>
        </>) : <></>}
        <br></br>
        <br></br>

      </MKBox>

    </>
  );
}
