// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// Pages
//le raccouci link
import Languages from "layouts/pages/admin-pages/administration";
import Slides from "layouts/pages/admin-pages/slides";
import Sections from "layouts/pages/admin-pages/rubriques";
import Categories from "layouts/pages/admin-pages/categories";
import Subcategories from "layouts/pages/admin-pages/subcategories";
import Items from "layouts/pages/admin-pages/items";
import Maps from "layouts/pages/admin-pages/maps";
import Users from "layouts/pages/admin-pages/users";
import Locations from "layouts/pages/admin-pages/locations";

const routes = [
  {
    name: "Admin",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Administration",
        collapse: [
          {
            name: "Language",
            route: "/pages/admin-pages/administration",
            component: <Languages />,
          },
          {
            name: "Slide",
            route: "/pages/admin-pages/slides",
            component: <Slides />,
          },
          {
            name: "Section",
            route: "/pages/admin-pages/rubriques",
            component: <Sections />,
          },
          {
            name: "Category",
            route: "/pages/admin-pages/categories",
            component: <Categories />,
          },
          {
            name: "Subcategory",
            route: "/pages/admin-pages/subcategories",
            component: <Subcategories />,
          },
          {
            name: "Item",
            route: "/pages/admin-pages/items",
            component: <Items />,
          },
          {
            name: "Map",
            route: "/pages/admin-pages/maps",
            component: <Maps />,
          },
          {
            name: "User",
            route: "/pages/admin-pages/users",
            component: <Users />,
          },
          {
            name: "Location",
            route: "/pages/admin-pages/locations",
            component: <Locations />,
          },
        ],
      },
    ],
  },
];

export default routes;
