/* eslint-disable */
import React, { useState } from "react";
// used for making the prop types of this component
import Axios from "axios";
import ApiConfig from "_config/ApiConfig";
import PropTypes from "prop-types";
// core components
//import Button from "components/CustomButtons/Button.js";
import MKButton from "components/MKButton";

import defaultImage from "assets/images/image_placeholder.png";
import defaultAvatar from "assets/images/placeholder.jpg";
import MKBox from "components/MKBox";
import './styles.css';


export default function ImageUpload(props) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [messageAdd, resultAddPhoto] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  let fileInput = React.createRef();

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setFileName(file.name);

      setImagePreviewUrl(reader.result);
      if (props.onChange) {
        props.onChange(file);
      }
    };
    reader.readAsDataURL(file);
  };


  const handleSubmit = async (e) => {
    const formData = new FormData();
    if(sessionStorage.getItem("table")=='a_tblrubrique'){
    //console.log(e);
    
    formData.append('file', file, fileName);
    formData.append('id', sessionStorage.getItem("id_rub"));
    formData.append('position', e);
    formData.append('table', sessionStorage.getItem("table"));
  }
  if(sessionStorage.getItem("table")=='a_tbltheme'){
    //console.log(e);
    //const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('id', sessionStorage.getItem("id_the"));
    formData.append('position', e);
    formData.append('table', sessionStorage.getItem("table"));
  }
  if(sessionStorage.getItem("table")=='cat_tblcategorie'){
    //console.log(e);
    //const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('id', sessionStorage.getItem("id_cat"));
    formData.append('position', e);
    formData.append('table', sessionStorage.getItem("table"));
  }
  if(sessionStorage.getItem("table")=='cat_tblsubcategorie'){
    //console.log(e);
    //const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('id', sessionStorage.getItem("id_sub"));
    formData.append('position', e);
    formData.append('table', sessionStorage.getItem("table"));
  }
  if(sessionStorage.getItem("table")=='cat_tblstandard'){
    //console.log(e);
    //const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('id', sessionStorage.getItem("id_std"));
    formData.append('position', e);
    formData.append('table', sessionStorage.getItem("table"));
  }  
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUploadPicture.php?`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(res => {
          //console.log(res.data.picturedata)
          if (res.data.picturedata.success === true) {
            resultAddPhoto('Photo ajouté');
            setFile(null);//hide buttons
            
          }
          else {
            resultAddPhoto('error on load');
          }
          return;
        })
    } catch (ex) {
      console.log(ex);
    }
   
   /* else{
   setFile(null);//hide buttons
   resultAddPhoto('Créer le produit avant de charger une photo');
   return;
  } */
  };
  // eslint-disable-next-line
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps, uploadButtonProps, id } = props;

  return (
    
    <div className="fileinput text-center">
      <input type="file" style={{display:'none'}} onChange={handleImageChange} ref={fileInput} />
      <div style={{width: '50%'}} className={"thumbnail" + (avatar ? " img-circle" : "")}>
            <MKBox position="relative">
              <MKBox component="img" src={imagePreviewUrl} alt="..." width="100%" />
            </MKBox>
    {/*     <img src={imagePreviewUrl} alt="..." /> */}
      </div>
      <div>
        {file === null ? (
           <MKButton {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Ajouter une photo" : "Charger une photo"}
          </MKButton>
        ) : (
          <span>
            <MKButton {...changeButtonProps} onClick={() => handleClick()}>
              Changer
            </MKButton>
            {avatar ? <br /> : null}
            <MKButton {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Supprimer
            </MKButton>
            <MKButton {...uploadButtonProps} onClick={() => handleSubmit(id)}>
              {/* <i className="fas fa-times" />  */}
              Enregistrer
            </MKButton><br />
          </span>
        )}
        <h6>{messageAdd}</h6>
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  uploadButtonProps: PropTypes.object,
  // it is a function from which you can get the files and fileNames that were uploaded
  // more can be read here: https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  onChange: PropTypes.func,
};
