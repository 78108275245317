/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import ApiConfig from "_config/ApiConfig";
// @mui material components
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Icon from "@mui/material/Icon";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
//import DefaultFooter from "examples/Footers/DefaultFooter";
// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter"; /**/

import MuiLink from "@mui/material/Link";
import Switch from "@mui/material/Switch";

// Routes
import routes from "routes";
//import footerRoutes from "footer.routes";
// Images
import bgImage from "assets/images/select.jpg";

export default function AdminLangage() {

  const [dropdown, setDropdown] = useState(null);
  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);
  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };
  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const [isListLanguage, setListLanguage] = useState([]);//rempli la liste 
  const [isCountLanguage, setCountLanguage] = useState(0);// compte les elements de la liste

  const [isCreateLanguage, createLanguage] = useState(0);//affichage a 1 du combo de creation
  const [isEditLanguage, editLanguage] = useState(0);//affichage a 1 du combo de mise a jour

  const [isSetShowInformation, setShowInformation] = useState(0);
  const [isSetLangage, setLangage] = useState([]);
  //const [isSetHideShow, setHideShow] = useState(0);A FAIRE

  const [isLibelle, setLibelle] = useState();
  const [messageCreate, resultCreate] = useState([]);

  const [messageMaj, resultMaj] = useState([]);
  const [message, searchResult] = useState([]);
  const [addLangageInfo, setLangageInfo] = useState({
    langageLabel: '',
  })

  const onChangeValueMix = (e) => {
    setLibelle(e.target.value);

    setLangageInfo({
      ...addLangageInfo,
      [e.target.name]: e.target.value
    })
  }

  //PARTIEL
  const onChangeValue = (e) => {
    setNameLanguage({
      ...isNameLanguage,
      [e.target.name]: e.target.value
    });
    setNameFlag({
      ...isNameFlag,
      [e.target.name]: e.target.value
    });
  }

  const [isNameLanguage, setNameLanguage] = useState({
    nameLanguage: '',
  });
  const [isNameFlag, setNameFlag] = useState({
    nameFlag: '',
  });

  //FONCTION
  const getAllLanguage = () => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetAllLangage.php`)
        .then(res => {
          if (res.data.success === true) {
            setListLanguage(res.data.languagedata);
            setCountLanguage(res.data.recordCount);
          }
          else {
            console.log('error');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const createTitleLanguage = async (event) => {
    if (isNameLanguage.nameLanguage.length <= 2) {
      resultCreate('3 characters minimum');
      return false;
    }
    else {
      resultCreate();
    }
    if (isNameFlag.nameFlag.length <= 2) {
      resultCreate('3 characters minimum');
      return false;
    }
    else {
      resultCreate();
    }
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectInsertLangage.php`, {
        id_pro: 1,
        chaine: isNameLanguage.nameLanguage,
        flag: isNameFlag.nameFlag,
      })
        .then(res => {
          if (res.data.success === true) {
            getAllLanguage();
            resultCreate('Registred Information');
          }
          else {
            resultCreate('error on registred');
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const getLangage = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetLangage.php`, {
        id_lan: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(1);
            setLangage(res.data.getlanguagedata);
            setLibelle(res.data.design_lan),
            resultMaj()
          }
          return;
        })
    } catch (error) { throw error; }
  };

  const updateLangage = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectUpdateLangage.php`, {
        id_lan: sessionStorage.getItem("id_lan"),
        libelleLangage: addLangageInfo.langageLabel,
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('Information updated');
            getAllLanguage();
          }
          else {
            resultMaj('error on updated');
          }
        })
    } catch (error) { throw error; }
  };

  const delLangage = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectDeleteLangage.php`, {
        id_lan: sessionStorage.getItem("id_lan"),
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('Information deleted');
            sessionStorage.removeItem("id_lan");
            getAllLanguage();
          }
          else {
            resultMaj('error on deleted');
          }
        })
    } catch (error) { throw error; }
  };


  const hideLangage = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectHideShowLangage.php`, {
        id_lan: sessionStorage.getItem("id_lan"),
        id_pro: 2,
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('hidden Information');
            getAllLanguage();
          }
          else {
            resultMaj('error on hide');
          }
        })
    } catch (error) { throw error; }
  };

  const showLangage = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectHideShowLangage.php`, {
        id_lan: sessionStorage.getItem("id_lan"),
        id_pro: 1,
      })
        .then(res => {
          if (res.data.success === true) {
            resultMaj('show Information');
            getAllLanguage(); 
          }
          else {
            resultMaj('error on show');
          }
        })
    } catch (error) { throw error; }
  };
  const getLangageSelect = async (id_lan) => {
    //console.log(id_rub);
    sessionStorage.setItem("id_lan", id_lan);
    sessionStorage.setItem("table", "");
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectGetLangage.php`, {
        id_lan: id_lan,
      })
        .then(res => {
          if (res.data.success === true) {
            searchResult(res.data.design_lan+" selected")
          }
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
   // getAllLanguage();//chargemement de la liste par defaut
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.01),
              rgba(gradients.dark.state, 0.01)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, lg: 12, md: 12 }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h4"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            ><br></br>
              <br></br><br></br>
              <br></br>
              User Admin
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {isCountLanguage} founds
              <br></br>
            </MKTypography>
             <MKTypography variant="body1" color="warning" opacity={0.8} mt={1} mb={3}>              
                          <h4>{message}</h4>
                        </MKTypography>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="center">
          <Stack direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 6 }}>
            <MKButton variant="gradient" color="info" onClick={openDropdown}>
              Show language <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKButton>
         
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
              {isListLanguage.map((item) => (
                <MenuItem key={item.id_lan}
                  value={item.id_lan}
                  onClick={() => (getLangageSelect(item.id_lan), createLanguage(0), editLanguage(0))}>
                  {item.design_lan}
                </MenuItem>
              ))}
            </Menu>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={() => (createLanguage(1), editLanguage(0))}>
              create Language
            </MKButton>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={() => (editLanguage(1), createLanguage(0))}>
              edit/delete/hide Language
            </MKButton>

          </Stack>
        </Grid> */}
        {isCreateLanguage === 1 ? (
          <>
            {/* CREATION D'UN LANGAGE */}
            <MKBox px={1} width="100%" height="60vh" mx="auto" position="relative" zIndex={2}>
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                  <Card>
                    <MKBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      mx={6}
                      mt={-3}
                      p={2}
                      mb={1}
                      textAlign="center"
                    >
                      <MKTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Language
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={4} pb={3} px={3}>
                      <MKBox component="form" role="form" onSubmit={createTitleLanguage}>
                        <MKBox mb={4}>
                          <MKInput
                            id="nameLanguage"
                            name="nameLanguage"
                            variant="outlined"
                            onChange={onChangeValue}
                            label="Language Name"
                            placeholder="Write name"
                            autoComplete="off"
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </MKBox>
                        <MKBox mb={2}>
                          <MKInput
                            id="nameFlag"
                            name="nameFlag"
                            variant="outlined"
                            onChange={onChangeValue}
                            label="Flag Name"
                            placeholder="Write name"
                            autoComplete="off"
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </MKBox>
                        <MKBox mt={4} mb={1} px={5}>
                          <MKButton variant="gradient" color="info" fullWidth onClick={createTitleLanguage}>
                            Save
                          </MKButton>
                          <MKTypography variant="button" color="text" fontWeight="medium">
                            {messageCreate}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </Card>
                </Grid>
              </Grid>
            </MKBox>
          </>
        ) :
          <></>}
        {isEditLanguage === 1 ? (<>
          <MKBox px={1} width="100%" height="60vh" mx="auto" position="relative" zIndex={2}>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={11} sm={9} md={10} lg={8} xl={8}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={6}
                    mt={-3}
                    p={1}
                    mb={1}
                    textAlign="center"
                  >
                    <MKTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                      Languages list
                    </MKTypography>
                  </MKBox>
                  <Grid container>
                    <Grid size={{ xs: 10, lg: 5, md: 5 }} mx="auto">
                      {/*  <MKTypography variant="h5" color="white" my={1}>
                 Titre
                </MKTypography> */}
                      <br></br>
                      {isListLanguage.map((item) => (
                        <>
                          <MKTypography
                            component="a"
                            href="#"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                              width: "max-content",
                              display: "flex",
                              alignItems: "center",
                              "& .material-icons-round": {
                                fontSize: "1.125rem",
                                transform: "translateX(3px)",
                                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                              },
                              "&:hover .material-icons-round, &:focus .material-icons-round": {
                                transform: "translateX(6px)",
                              },
                            }}
                            key={item.id_lan}
                            onClick={() => getLangage(item.id_lan)}
                          >
                             {item.id_pro == 1 ? <>
                                <VisibilityIcon />
                              </> : <><VisibilityOffIcon /> </>}&nbsp; {item.design_lan}
                            <Icon sx={{ fontWeight: "bold" }} onClick={() => getLangage(item.id_lan)}>arrow_forward</Icon>
                          </MKTypography>
                        </>
                      ))}
                    </Grid>
                    <Grid size={{ xs: 10, lg: 5, md: 5 }} mx="auto">
                      {isSetShowInformation === 1 ? (<>
                        {isSetLangage.map((itemLangage) => (
                          <>
                            <form onSubmit={updateLangage}>
                              <MKTypography variant="h5" my={1} color="text">
                                {itemLangage.design_lan}
                              </MKTypography>
                              <MKInput
                                id="langageLabel" name="langageLabel"
                                variant="standard"
                                onChange={onChangeValueMix}
                                value={isLibelle}
                                autoComplete="off"
                                required
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={updateLangage}>save</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                  Save changes
                                </MKTypography>
                              </MKBox>
                            </form>
                            <form onSubmit={delLangage}>
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={delLangage}>delete</Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                  Delete information
                                </MKTypography>
                              </MKBox>
                            </form>
                            <form onSubmit={hideLangage}>
                              <MKBox display="flex" alignItems="left" p={1}>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={hideLangage}><VisibilityOffIcon /></Icon>
                                </MKBox>
                                <MKTypography variant="body2" color="text" pl={1}>
                                </MKTypography>
                                <MKBox
                                  width="3rem"
                                  height="3rem"
                                  variant="gradient"
                                  bgColor="info"
                                  color="white"
                                  coloredShadow="info"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius="xl"
                                >
                                  <Icon fontSize="small" onClick={showLangage}><VisibilityIcon /></Icon>
                                </MKBox>
                              </MKBox>
                              <h6>{messageMaj}</h6>
                            </form>
                          </>
                        )
                        )}
                      </>) : (<></>)
                      }
                    </Grid>
                  </Grid>
                  <br></br>
                </Card>
              </Grid>
            </Grid>
          </MKBox>

          <br></br>
       
        </>) : <></>}
      </MKBox>
    </>
  );
}
