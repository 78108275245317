/* eslint-disable */
import React, { useState } from "react";
import Axios from "axios";
import ApiConfig from "_config/ApiConfig";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
//import MuiLink from "@mui/material/Link";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/select.jpg";

function SignInConnect() {
  const [userInfo, setuserInfo] = useState({
    login: 'select@select.com',
    pass: '',
  });

const navigate = useNavigate();

  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  const [message, resultCheckAccount] = useState([]);

  const submitUser = async (event) => {
    //console.log(userInfo.login);
    //console.log(userInfo.pass);
    try {
      event.preventDefault();
      event.persist();
      sessionStorage.clear();
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/SelectCheckUser.php`, {
        userlogin: userInfo.login,
        userpass: userInfo.pass,
      })
        .then(res => {
          if (res.data.success === true) {
            sessionStorage.setItem("statut_clt", res.data.userdata.statut_clt);
            sessionStorage.setItem("id_clt", res.data.userdata.id_clt);
           /*  sessionStorage.setItem("n4_prv", res.data.userprivilegedata['n4_prv']);
            sessionStorage.setItem("n6_prv", res.data.userprivilegedata['n6_prv']);
            sessionStorage.setItem("n7_prv", res.data.userprivilegedata['n7_prv']);
            sessionStorage.setItem("n9_prv", res.data.userprivilegedata['n9_prv']);
            sessionStorage.setItem("n10_prv", res.data.userprivilegedata['n10_prv']); */

            navigate("/pages/admin-pages/administration");
          }
          else {
            resultCheckAccount('wrong identification');
          }
          return;
        })
    } catch (error) { throw error; }
  };
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={submitUser}>
                  <MKBox mb={2}>
                    <MKInput
                      id="login"
                      name="login"
                      variant="outlined"
                      onChange={onChangeValue}
                      label="Your ID"
                      placeholder="Write ID"
                      autoComplete="on"
                      required
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      id="pass"
                      name="pass"
                      type
                      variant="outlined"
                      onChange={onChangeValue}
                      label="Password"
                      autoComplete="on"
                      required
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={submitUser}>
                      Connect
                    </MKButton>
                    <MKTypography variant="button" color="text" fontWeight="medium">
                      {message}
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInConnect;
