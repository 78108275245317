/* eslint-disable */
import axios from "axios";

const dev = false; //false

const localUrl = 'http://localhost/SELECT';
const prodUrl = "https://admin.select.guide";
const baseUrl = dev ? localUrl : prodUrl;
//const baseUrl = prodUrl;

const baseUrlApi = `${baseUrl}`;
const baseUrlFile = "";

axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.response.status === 401) {
      console.log("unauthorized, logging out ...");
    }
    return Promise.reject(error.response);
  }
); 

axios.defaults.baseURL = baseUrlApi;
axios.defaults.withCredentials = true;
const ApiConfig = {
  baseUrl,
  baseUrlApi,
  baseUrlFile,
};

/* export const init = () => {
  console.log("API INITIALIZED");
}; */

export default ApiConfig;
